import './App.scss'
import logo from './images/logo.png'

function App() {
  return (
    <div className="App">
      <header>
        <div className="top-bar">
          <div className="logo">
            <img src={logo} alt="Dun & Bradstreet"/>
          </div>
          <div className="divider">|</div>
          <div>
            <span className="app-title">Kollektion</span>
          </div>
        </div>
      </header>
      <main>
        <section className="content grid">
          <div className="row">
            <div className="col-left">
              <h1>Välkommen till Dun & Bradstreet Kollektion!</h1>
              <p>Våra produkter och tjänster gör det möjligt för dig att ringa in din målgrupp och skapa relevanta segment
                för personlig kommunikation och åtgärder. Dessutom kan vi hjälpa dig att förutsäga framtida köpbeteenden och
                identifiera möjligheterna som ger störst ROI.</p>
              <p>Kontakta oss:<br/>
                +46 (0) 8-558 059 00<br/>
                <a href="mailto:customerservice.se@dnb.com">customerservice.se@dnb.com</a></p>
            </div>
            <div className="col-right">
              <div className="row logo">
                <img src={logo} className="logo" alt="Dun & Bradstreet"/>
              </div>
              <div className="row login-button-row">
                <a className="button" href="/cst-w/">Logga in</a>
              </div>
            </div>
          </div>
        </section>
      </main>
      
      <footer>
        <div className="fixed-bottom">
          Ansvarig utgivare Thomas Thornberg, utsedd av Dun & Bradstreet Credit
          AB
        </div>
      </footer>
    </div>
  )
}

export default App
